import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Features from "../components/features";
import { StaticImage } from "gatsby-plugin-image";

export default function CitiesPostTemplate({ data: { markdownRemark } }) {
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Seo title={frontmatter.title} />

      <div className="relative bg-white overflow-hidden">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">

              <div className="mt-6">

              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <span className="">Dirty cars</span>{' '}
                <span className="text-green-500">wanted</span>
              </h1>


                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                {frontmatter.title}  Mobile Auto Detailing
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                It’s what we do—better than anyone else in Seattle.
                <br/><br/>
                With our unique one-step steam cleaning process, we can efficiently clean, disinfect and shine even the dirtiest of vehicles.
                <br/><br/>
                Best of all? We use a special waterless wash process that not only gives your vehicle its shiny new look back, but it’s also twice as strong as your regular car wash—so you won’t need another cleaning for a while.
                </p>
              </div>

          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 max-w-xl">
            <div className="md:pl-4 md:-mr-48 sm:pl-6 lg:py-16 lg:px-0 lg:m-0 lg:relative lg:h-full">



            <StaticImage
              src="../images/dirty-car.jpg"
              alt="boat detailing"
              className="w-full h-3/4	rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:w-auto lg:max-w-none"
              loading="lazy"
            />
            </div>
          </div>
        </div>

        <Features/>


        <div className="bg-green-500 mt-16">
          <div className="max-w-7xl mx-auto text-center py-12 px-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
              <span className="block">Ready For A Clean Car?</span>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="flex rounded-md w-full">
                <a target="_blank" rel="noopener noreferrer" href="https://book.steamseattle.com?utm_source=steamseattle&utm_medium=landing&utm_campaign=citypages" className="w-full inline-block items-stretch justify-center px-5 py-3 border border-transparent text-xl font-extrabold rounded-md text-gray-800 bg-white hover:bg-gray-200">
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/*
      <div className="post-body" dangerouslySetInnerHTML={{ __html: html }} />
      */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title

      }
    }
  }
`;
